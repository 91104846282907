
import Vue from 'vue';
import FormItem from '@/components/base/form-item/';
import Zbtn from '@/components/base/btn/';
import ZbtnGroup from '@/components/base/btn-group/';

export default Vue.extend({
  name: 'AdminCommentFilter',
  components: {
    FormItem,
    Zbtn,
    ZbtnGroup,
  },
  data() {
    return {
      formData: {
        status: 'all',
      },
    };
  },
  computed: {
    statusList() {
      return [
        {
          label: '全部',
          value: 'all',
        },
        {
          label: '审核通过',
          value: true,
        },
        {
          label: '未审核',
          value: false,
        },
      ];
    },
  },
  methods: {
    /**
     * @desc
     */
    handleChangeStatus(value) {
      this.$set(this.formData, 'status', value);
    },
  },
});
